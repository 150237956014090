.cols {
  display: flex;
  flex-direction: column;
}
.cols > div {
  margin: 10px;
}

.rows {
  display: flex;
  flex-direction: row;
}
.rows > div {
  margin: 10px;
}

.in-msg {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100px;
}

.in-msg li:last-child {
  position:sticky;
  bottom:0;
}

.patricipants {
  overflow-x: hidden;
  overflow-y: auto;
  height: 50px;
}

.frame {
  border-style: solid;
}